<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="使用单位名称" prop="registernum">
              <a-input v-model.trim="queryParams.registernum" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目名称" prop="registertime">
              <a-input v-model.trim="queryParams.registertime" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="下次检验时间" prop="nexttime">
              <a-input v-model.trim="queryParams.nexttime" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-button type="primary" style="margin-left: 8px;" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="detectionproid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.detectionproid">详情</a-menu-item>
                <a-menu-item :key="'delete-'+record.detectionproid">修改</a-menu-item>
                <a-menu-item :key="'export-'+record.detectionproid">导出</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-form :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-form>
  </page-container>
</template>

<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {getItemFromArrayByKey} from "U/index";
import addOrEditForm from "V/backlogs/detectionmanage/detectionproappc/addOrEditForm";
export default {
  name: 'elevatorArchives',
  mixins: [pagination],
  components: {
    addOrEditForm,
  },
  props: ['pageType'],
  data() {
    return {
      moment,
      showAdvanced: false,
      queryParams: {
        registertime:'',
        registernum:'',
        nexttime:'',
        is96333:'',
        remarks:'',
      },
      tableColumns: [
        {
          title: '使用单位名称',
          dataIndex: 'depname',
          key: 'depname',
          ellipsis: true,
        },
        {
          title: '项目名称',
          dataIndex: 'proname',
          key: 'proname',
          ellipsis: true,
        },
        {
          title: '审批结果',
          dataIndex: 'approved',
          key: 'approved',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      pageTitle:'电梯检测项目',
      counts:'',
    }
  },
  computed: {
    breadcrumb() {
      return [
        {
          name: '我的待办',
          path: ''
        },
        {
          name: '电梯检测管理',
          path: ''
        },
        {
          name: '项目资格申请',
          path: ''
        },
      ]
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      // this.tableLoading = true;
    },
    showModal(){
      this.modalVisible=true
    },
    deleteConfirm(value, record) {
      this.$confirm({
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.liftid);
        },
        onCancel() {},
      });
    },
    delete() {
      this.showLoading();
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let detectionproid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'detectionproid', detectionproid);
      if(type == 'detail') {
        this.showModal('detail', detectionproid, record)
      } else if(type == 'edit') {
        this.showModal('edit', detectionproid, record)
      }else {
      }
    },
  }
}
</script>
<style lang="scss" scoped>
</style>