<template>
  <div>
    <a-modal v-model="modalVisible" title="新增" :width="900" :centered="true" :body-style="modalBodyStyle">
      <a-tabs type="card">
        <a-tab-pane key="1" tab="申请信息">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'" type="primary" @click="confirm('1')">提交</a-button>
        <a-button v-if="showType=='check'" @click="confirm('3')">审核不通过</a-button>
        <a-button v-if="showType=='check'" type="primary" @click="confirm('2')">审核通过</a-button>
      </template>
      <div class="box-container" style="margin-top: 10px;">
        <div class="box-container-inner">
      <div class="table-header">
        <h3 class="table-title">试点电梯清单</h3>
        <div class="table-btns">
          <a-button type="primary" style="margin-left: 8px;" @click="showModal('add')"><a-icon type="plus"></a-icon>导入</a-button>
          <a-button type="primary" style="margin-left: 8px;" @click="showModal('add')"><a-icon type="plus"></a-icon>新增</a-button>
          <a-button type="primary" style="margin-left: 8px;" @click="showModal('add')"><a-icon type="plus"></a-icon>审批</a-button>
          <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
        </div>
      </div>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="maintainproid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintainproid">详情</a-menu-item>
                <a-menu-item :key="'delete-'+record.maintainproid">修改</a-menu-item>
                <a-menu-item :key="'export-'+record.maintainproid">导出</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
        </div>
      </div>
      </a-tab-pane>
      <a-tab-pane v-if="" key="2" tab="流程追踪">
        <a-timeline>
          <a-timeline-item v-for="(item, index) in wrokflow" :key="index" :color="index<wrokflowInex?'green':'gray'">
            <div>操作步骤: {{liftapplicationsteps[item.flowstep]}}</div>
            <div>操作人员: {{item.person}}</div>
            <div v-if="item.flowstep=='40'">退出原因: {{item.reason}}</div>
            <div>完成时间: {{moment(item.finishtime,'YYYYMMDDHHmmss').format("YYYY-MM-DD HH:mm:ss")}}</div>
          </a-timeline-item>
        </a-timeline>
      </a-tab-pane>
      </a-tabs>
    </a-modal>
    <add-lift-list :visible.sync="formVisible" :show-type="formShowType" :detailData="formDetailData"></add-lift-list>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import pagination from '@/mixins/pagination'
import addLiftList from "V/backlogs/detectionmanage/detectionproappc/addLiftList";
import liftapplicationsteps from "@/json/liftapplicationsteps";
import {findWorkflowByorderId} from "A/wbgl";
export default {
  mixins: [pagination],
  components: {
    addLiftList,
  },
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      liftapplicationsteps,
      modalBodyStyle: {
        height: '560px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formDatas: {
        depname:'',
        address:'',
        creditcode:'',
        license:'',
        usefulife:'',
        licenselevel:'',
        applyperson:'',
        applyphone:'',
        bignum:null,
        liftnumber:'',
        insurancebuy:'',
        public:'',
        hasiot:'',
        principal:'',
      },
      //这里面的数据属于必填项
      formRules: {
      },
      tableColumns: [
        {
          title: '电梯登记证编号',
          dataIndex: 'registernum',
          key: 'registernum',
          ellipsis: true,
        },
        {
          title: '使用登记日期',
          dataIndex: 'registertime',
          key: 'registertime',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      formVisible: false,
      formShowType: '',
      formDetailData: null,
      userloginurlList: [],
      wrokflow: [
        {
          flowstep:'10',
          person:'超级管理员',
          finishtime:'20211206160650'
        },
        {
          flowstep:'20',
          person:'超级管理员',
          finishtime:'20211206161650'
        }
      ],
      wrokflowInex:20,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    resetDatas(){

    },
    getWorkFlow() {
      let params = {
        maintenancorderid: this.detailData.maintenancorderid
      };
      findWorkflowByorderId(params).then(res => {
        if(res && res.returncode == '0') {
          this.wrokflow = res.item;
          this.getWorkFlowIndex();
        }
      })
    },
    getWorkFlowIndex() {
      if(this.formDatas.processstep && this.wrokflow && this.wrokflow.length) {
        this.wrokflow.forEach((item, index) => {
          if(item.flowstep == this.formDatas.processstep) {
            this.wrokflowInex = index;
          }
        })
      }
    },
    confirm(checkstatus) {
    },
    showModal(){
      this.formVisible=true
    },
  }
}
</script>